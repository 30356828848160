<script lang="ts" setup>

  // Cookie
  const route = useRoute()
  if(route.query.utm_source) {
    const utm_source = useCookie('utm_source')
    utm_source.value = route.query.utm_source
  }
  if(route.query.utm_medium) {
    const utm_medium = useCookie('utm_medium')
    utm_medium.value = route.query.utm_medium
  }
  if(route.query.utm_campaign) {
    const utm_campaign = useCookie('utm_campaign')
    utm_campaign.value = route.query.utm_campaign
  }
  if(route.query.utm_term) {
    const utm_term = useCookie('utm_term')
    utm_term.value = route.query.utm_term
  }
  if(route.query.utm_content) {
    const utm_content = useCookie('utm_content')
    utm_content.value = route.query.utm_content
  }

  onMounted(() => {

    window.CallTrkSwap.swapEntry()
    
    const gtm = useGtm()

    document.addEventListener('click', function(e){
      if(e?.target?.closest('a[href^="tel:"]')){
        gtm?.trackEvent({
            event: 'telephoneClicked',
            label: `Telephone Clicked`,
        })

      }
    })

    window.addEventListener('message',
      (event) => {
        if(event.data === 'spruce_customer_requested_estimate') {
          gtm?.trackEvent({
              event: 'formSubmission',
              label: `Heat Pump Estimate - Form Submission`,
          })
        }
      }, false
    )
    
  })

  watch(() => route.fullPath, () => {
     
    window.CallTrkSwap.swapEntry()

  })

</script>

<template>
  
  <VitePwaManifest />
  <NuxtLoadingIndicator color="#00755f" :height="2" :duration="5000"/>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

</template>

<style lang="scss">

  header {
    
    .toggle:after {
      background-image: url("data:image/svg+xml,%0A%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.564 8.116'%3E%3Cpath id='path-1' d='M9.678,1.1l8.029,6.5a.7.7,0,0,1,.1.984l-.173.215a.7.7,0,0,1-.985.1L9.184,2.855,1.717,8.9A.7.7,0,0,1,.8,8.874L.732,8.8.559,8.584a.7.7,0,0,1,.1-.984h0L8.691,1.1A.7.7,0,0,1,9.184.944.7.7,0,0,1,9.678,1.1Z' transform='translate(-0.403 -0.942)' style='fill:%23ffffff;fill-rule:evenodd'/%3E%3C/svg%3E");
      background-size:18px;
      background-position: center center;
      background-repeat: no-repeat;
    }
    
    .toggle--dark:after {
      background-image: url("data:image/svg+xml,%0A%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.564 8.116'%3E%3Cpath id='path-1' d='M9.678,1.1l8.029,6.5a.7.7,0,0,1,.1.984l-.173.215a.7.7,0,0,1-.985.1L9.184,2.855,1.717,8.9A.7.7,0,0,1,.8,8.874L.732,8.8.559,8.584a.7.7,0,0,1,.1-.984h0L8.691,1.1A.7.7,0,0,1,9.184.944.7.7,0,0,1,9.678,1.1Z' transform='translate(-0.403 -0.942)' style='fill:%23002b40;fill-rule:evenodd'/%3E%3C/svg%3E");
      background-size:18px;
      background-position: center center;
      background-repeat: no-repeat;
    }

    & + main {
      @apply mt-10 xl:mt-0
    }

    &.has-submenu + main {
      @apply mt-18 xl:mt-0
    }

  }

</style>