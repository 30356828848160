import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_939WXwe8igqnMeta } from "/opt/build/repo/pages/get-a-quote/[...slug].vue?macro=true";
import { default as index1JtnvB3LKAMeta } from "/opt/build/repo/pages/get-a-quote/heat-pump/index.vue?macro=true";
import { default as indexfZVH2TK962Meta } from "/opt/build/repo/pages/get-a-quote/solar/index.vue?macro=true";
import { default as _91_46_46_46slug_93eR0FzCjECsMeta } from "/opt/build/repo/pages/landing/[...slug].vue?macro=true";
import { default as component_45stubVlEI75ehPBMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubVlEI75ehPB } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91_46_46_46slug_93EhWyQvnGPmMeta?.name ?? "slug",
    path: _91_46_46_46slug_93EhWyQvnGPmMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93EhWyQvnGPmMeta || {},
    alias: _91_46_46_46slug_93EhWyQvnGPmMeta?.alias || [],
    redirect: _91_46_46_46slug_93EhWyQvnGPmMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_939WXwe8igqnMeta?.name ?? "get-a-quote-slug",
    path: _91_46_46_46slug_939WXwe8igqnMeta?.path ?? "/get-a-quote/:slug(.*)*",
    meta: _91_46_46_46slug_939WXwe8igqnMeta || {},
    alias: _91_46_46_46slug_939WXwe8igqnMeta?.alias || [],
    redirect: _91_46_46_46slug_939WXwe8igqnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/get-a-quote/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index1JtnvB3LKAMeta?.name ?? "get-a-quote-heat-pump",
    path: index1JtnvB3LKAMeta?.path ?? "/get-a-quote/heat-pump",
    meta: index1JtnvB3LKAMeta || {},
    alias: index1JtnvB3LKAMeta?.alias || [],
    redirect: index1JtnvB3LKAMeta?.redirect,
    component: () => import("/opt/build/repo/pages/get-a-quote/heat-pump/index.vue").then(m => m.default || m)
  },
  {
    name: indexfZVH2TK962Meta?.name ?? "get-a-quote-solar",
    path: indexfZVH2TK962Meta?.path ?? "/get-a-quote/solar",
    meta: indexfZVH2TK962Meta || {},
    alias: indexfZVH2TK962Meta?.alias || [],
    redirect: indexfZVH2TK962Meta?.redirect,
    component: () => import("/opt/build/repo/pages/get-a-quote/solar/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93eR0FzCjECsMeta?.name ?? "landing-slug",
    path: _91_46_46_46slug_93eR0FzCjECsMeta?.path ?? "/landing/:slug(.*)*",
    meta: _91_46_46_46slug_93eR0FzCjECsMeta || {},
    alias: _91_46_46_46slug_93eR0FzCjECsMeta?.alias || [],
    redirect: _91_46_46_46slug_93eR0FzCjECsMeta?.redirect,
    component: () => import("/opt/build/repo/pages/landing/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/sitemap.xml",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  }
]